<template>
  <div class="page">
    <div class="header">
      <div class="header-button">
        <el-button type="primary" size="mini" @click="goTo('/detection/importStudent')" round>批量导入学生</el-button>
      </div>
    </div>
    <div class="importList">
      <div class="schoolList">
        <el-link
          style="color: #333"
          :underline="false"
          @click="goTo('/detection/schoolList')">
          已导入学生的学校列表
        </el-link>
      </div>
      <div class="studentList">
        <el-link
          :underline="false"
          @click="goTo('/detection/student')"
        >
          已导入学生列表
        </el-link>
      </div>
    </div>

    <!-- 学校名称和返回按钮-->
    <div class="schoolTitle">
      <span>{{ schoolName }}</span>
      <el-button
        type="return" size="small"
       round icon="el-icon-arrow-left"
       @click="goTo('/detection/schoolList')"
        style="float: right;"
      >返回</el-button>
    </div>

    <div>
      <div style="display: flex;flex-direction: column;margin-bottom: 20px;" v-for="(item,index) in tableData" :key="index">
        <div class="grade">
          <span style="font-weight: 700">{{item.gradeName}}</span>
        </div>
        <el-row :gutter="20">
          <el-col :span="8"  v-for="(items,idx) in item.classData" :key="idx">
            <div class="grid-content">
              <span class="gClass">{{items.className}}</span>
              <el-link class="check" @click="goTo('/detection/student',items.info)">查看</el-link>
              <br><br><br><br>
              <div class="classNumber">
                <span>已导入学生：<span class="numberOfPeople">{{items.count}}</span></span>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import StudentsService from '@/globals/service/detection/Students.js'

export default {
  data () {
    return {
      schoolId: '',
      schoolName: '',
      tableData: [],
      tablePageData: []
    }
  },
  mounted () {
    const schoolId = this.$route.query.schoolId
    this.schoolName = this.$route.query.schoolName
    this.getListData(schoolId)
  },
  methods: {
    goTo (path, query) {
      this.$router.push({ path: path, query: query })
    },
    getListData (schoolId) {
      StudentsService.count({ schoolId: schoolId }).then(res => {
        // console.log('res', res)
        const data = res.data
        const tableData = []
        for (const i in data) {
          const obj = {}
          obj.gradeName = i
          obj.classData = []
          for (const ii in data[i]) {
            obj.classData.push(
              {
                className: ii,
                count: data[i][ii].length,
                info: data[i][ii][0]
              }
            )
          }
          tableData.push(obj)
        }
        this.tableData = tableData
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  display: flex;
  flex-direction: column;
  padding: 0 25px;
  background: #fff;
  text-align: center;
  min-height: 100%;
  color: #333;
  .page .header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px 0 16px;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 70px;
    border-bottom: 1px solid #CCCCCC;
    font-size: 15px;
    font-weight: 500;
    color: #333;

    span {
      border-left: 2px solid;
      padding: 0 8px;
      box-sizing: border-box;
    }
  }

  .schoolTitle {
    justify-content: center;
    font-size: 20px;
    font-family: PingFangSC-mini, PingFang SC;
    line-height: 28px;
  }

  .schoolTitle .el-button--info {
    float: right;
    margin-top: -5px;
    color: #333333;
    background-color: #F1F1F1;
  }
}

.el-button--info {
  background-color: #CCCCCC;
  color: red;
}

.grade {
  display: flex;
  float: left;
  font-size: 16px;
  font-family: PingFangSC-mini, PingFang SC;
  color: #333333;
  line-height: 22px;
}

.importList {
  display: flex;
  height: 20px;
  margin: 10px 0 20px 0;
  padding: 0 0 10px 0;
  border-bottom: 2px solid #CCCCCC;
}

.schoolList, .studentList {
  display: flex;
  float: left;
  padding-right: 40px;
}

.el-row {
  &:last-child {
    margin-bottom: 0;
  }

  .el-col {
    margin-top: 10px;
    border-radius: 4px;
  }

  .grid-content {
    height: auto;
    border: 1px solid #CCCCCC;
    background-color: white;
    border-radius: 4px;
    min-height: 36px;
  }

  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }

  .gClass{
    float: left;
    color: #333333;
    font-size: 16px;
    margin: 20px 0 0 20px;
    font-family: PingFangSC-mini, PingFang SC;
  }
  .check{
    float: right;
    color: #4DAF5A;
    margin: 20px 20px 0 0;
  }
  .classNumber{
    display: flex;
    vertical-align: top;
    margin-top: 10px;
    margin-left: 20px;
    margin-bottom: 20px;
  }
  .numberOfPeople {
    color: #006BE3;
  }
}

</style>
